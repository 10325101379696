
import styled from 'styled-components';


const IndicatorWrapper= styled.div`
display: flex;
  flex-wrap: nowrap;
  position: absolute;
  bottom: 15px;
  margin-left: -50px;
  left: 50%;
`;

const Dot = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: white;
  opacity: ${(props) => (props.isActive ? 1 : 0.3)};
  margin: 5px;
  transition: 750ms all ease-in-out;
`;
const SliderIndicator=({currentSlide, amountSlides, setSlide})=>{

    return (
        <IndicatorWrapper>
          {Array(amountSlides)
            .fill(1)
            .map((_, i) => (
              <Dot
                key={i}
                isActive={currentSlide === i}
                onClick={() => setSlide(i)}
              />
            ))}
        </IndicatorWrapper>
      );
}

export default SliderIndicator