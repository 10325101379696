import "./HorizontalLineBreak.css"
import { Link } from "react-router-dom";

const HorizontalLineBreak=({text})=>{

    return  <div className="horizontal__linebreak">
                <span className="content__in__linebreak">
                <Link to={"/shop"}>{text}</Link>
                </span>
            </div>

}

export default HorizontalLineBreak