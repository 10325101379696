import * as actionTypes from "../constants/servicesConstants";
import axiosInstance from '../../utils/api'
import setAlert from "./alertActions";
import { setState, generateReqStatusObject } from "../../utils/stateHelpers";


export const createService = (newService) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.CREATE_SERVICE_REQUEST });
    const { data } = await axiosInstance.post("services", newService);

    dispatch({
      type: actionTypes.CREATE_SERVICE_SUCCESS,
      payload: data,
    });
    dispatch(setAlert('Service created successfully.', 'success'))
  } catch (error) {
    
    const errMessage = error.response && error.response.data.message ? error.response.data.message : error.message
    dispatch({
      type: actionTypes.CREATE_SERVICE_FAIL,
      payload: errMessage
    });
  }
};

export const updateService = (service) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.UPDATE_SERVICE_REQUEST });

    const { data } = await axiosInstance.put(`services/${service._id}`, service);
    
    dispatch({
      type: actionTypes.UPDATE_SERVICE_SUCCESS,
      payload: data,
    });

    dispatch(setAlert('Service updated successfully.', 'success'));

  } catch (error) {
    const errMessage = error.response && error.response.data.message ? error.response.data.message : error.message
    dispatch({
      type: actionTypes.UPDATE_SERVICE_FAIL,
      payload: errMessage
    });
    
  }
};

export const deleteService = (serviceId) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.DELETE_SERVICE_REQUEST });
    const { data } = await axiosInstance.delete(`services/${serviceId}`);
    dispatch({
      type: actionTypes.DELETE_SERVICE_SUCCESS,
      payload: data,
    });
    dispatch(setAlert('Service deleted successfully.', 'success'))
  } catch (error) {
    const errMessage = error.response && error.response.data.message ? error.response.data.message : error.message
    dispatch({
      type: actionTypes.DELETE_SERVICE_FAIL,
      payload: errMessage
    });
  }
};


export const getServices = () => async (dispatch) => {
  
  try {
    dispatch({ type: actionTypes.GET_SERVICES_REQUEST });
      
      const { data } = await axiosInstance.get("services");
      dispatch({
        type: actionTypes.GET_SERVICES_SUCCESS,
        payload:data,
      });
  } catch (error) {
   
    const errMessage = error.response && error.response.data.message ? error.response.data.message : error.message
    dispatch({
      type: actionTypes.GET_SERVICES_FAIL,
      payload: errMessage
    });
  }
};

export const getService = (serviceId) => async (dispatch) => {
  
    try {
      dispatch({ type: actionTypes.GET_SERVICE_REQUEST });
        
        const { data } = await axiosInstance.get(`services/${serviceId}`);
        dispatch({
          type: actionTypes.GET_SERVICE_SUCCESS,
          payload:data,
        });
    } catch (error) {
     
      const errMessage = error.response && error.response.data.message ? error.response.data.message : error.message
      dispatch({
        type: actionTypes.GET_SERVICE_FAIL,
        payload: errMessage
      });
    }
  };


  /*
  USING SWR FOR FETCHING
  */ 

  export const setServices = (data, error) =>  (dispatch) => {
   const reqStatus = generateReqStatusObject(actionTypes.GET_SERVICES_REQUEST, actionTypes.GET_SERVICES_SUCCESS, actionTypes.GET_SERVICES_FAIL)
    setState( data, error, dispatch, reqStatus)
  };

  export const setService = (data, error) =>  (dispatch) => {
    const reqStatus = generateReqStatusObject(actionTypes.GET_SERVICE_REQUEST, actionTypes.GET_SERVICE_SUCCESS, actionTypes.GET_SERVICE_FAIL)
     setState( data, error, dispatch, reqStatus)
   };
