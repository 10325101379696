import * as actionTypes from "../constants/vehicleConstants";
export const vehicle = (state = {
      vehicles: [],
      loadingVehicles: true,
      errorVehicles: '' },
     action) => {


  switch (action.type) {
    //GET VEHICLES
    case actionTypes.GET_VEHICLES_REQUEST:
      return {
        ...state,
        loadingVehicles: true,
        errorVehicles:''
      };
    case actionTypes.GET_VEHICLES_SUCCESS:
      return {
        ...state,
        vehicles:action.payload,
        loadingVehicles: false,
      };
    case actionTypes.GET_VEHICLES_FAIL:
      return {
        ...state,
        loadingVehicles: false,
        errorVehicles: action.payload,
      };
      //CREATE PRODUCT
    case actionTypes.CREATE_VEHICLE_REQUEST:
      
      return {
        ...state,
        loadingVehicles: true,
      };
    case actionTypes.CREATE_VEHICLE_SUCCESS:
      return {
        ...state,
        loadingVehicles: false,
        vehicles:  [ action.payload, ...state.vehicles]
      };
    case actionTypes.CREATE_VEHICLE_FAIL:
      return {
        ...state,
        loadingVehicles: false,
        errorVehicles: action.payload,
      };
   //UPDATE
    case actionTypes.UPDATE_VEHICLE_REQUEST:
      return {
        ...state,
        loadingVehicles: true,
      };
    case actionTypes.UPDATE_VEHICLE_SUCCESS:
      const updatedList= state.vehicles.map(vehicle=>{
        if(vehicle._id===action.payload._id){
          return action.payload
        }
        return vehicle
      })
      return {
        ...state,
        loadingVehicles: false,
        vehicles: updatedList
      };
    case actionTypes.UPDATE_VEHICLE_FAIL:
      return {
        ...state,
        loadingVehicles: false,
        errorVehicles: action.payload,
      };
  
//DELETE PRODUCT
case actionTypes.DELETE_VEHICLE_REQUEST:
      return {
        ...state,
        loadingVehicles: true,
      };
    case actionTypes.DELETE_VEHICLE_SUCCESS:
      const updatedListAfterDelete= state.vehicles.filter(vehicle=> vehicle._id!==action.payload._id)
      return {
        ...state,
        loadingVehicles: false,
        vehicles: updatedListAfterDelete
      };
    case actionTypes.DELETE_VEHICLE_FAIL:
      return {
        ...state,
        loadingVehicles: false,
        errorVehicle: action.payload,
      };
      default:
        return state;
    }
}