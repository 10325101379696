import * as actionTypes from "../constants/productConstants";
export const product = (state = {
   products: {fetchedProducts: [], count:0},
   someProducts: [],
   relatedProducts: [],
    categories: [],
  productsCount: 0,
  outOfStockCount: 0,
  product:{  name:'', description: '', price: 0, brand:'', countInStock: 0, productId: '', images: [{image: '',imageId: ''}], category: '', subCategory: '', sales: 0
	 },
   brands: [],
   vehicleTypes: [],
   loading: true,
   loadingProductDetails:true,
   loadingRelatedProducts:true,
    loadingProducts: true, 
    loadingProductsCount: true,
    loadingVehicleTypes: true,
    errorVehicleTypes: '',
     error: '' },
     action) => {


  switch (action.type) {
    //GET PRODUCTS
    case actionTypes.GET_PRODUCTS_REQUEST:
      return {
        ...state,
        loadingProducts: true,
        error:''
      };
    case actionTypes.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: {fetchedProducts: action.payload.products ,count: action.payload.count},
        loadingProducts: false,
      };
    case actionTypes.GET_PRODUCTS_FAIL:
      return {
        ...state,
        loadingProducts: false,
        error: action.payload,
      };
    //GET SOME PRODUTS 
    case actionTypes.GET_SOME_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
        // error:''
      };
    case actionTypes.GET_SOME_PRODUCTS_SUCCESS:
      return {
        ...state,
        someProducts: action.payload,
        loading: false,
      };
    case actionTypes.GET_SOME_PRODUCTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      //GET CATEGORIES
      case actionTypes.GET_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
        loading: false,
      };
    case actionTypes.GET_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
  // GET RELATED PRODUCTS ----> JUST 3 PRODUCTS 
     case actionTypes.GET_RELATED_PRODUCTS_REQUEST:
      return {
        ...state,
        loadingRelatedProducts: true,
        
      };
    case actionTypes.GET_RELATED_PRODUCTS_SUCCESS:
      return {
        ...state,
        relatedProducts: action.payload,
        loadingRelatedProducts: false,
      };
    case actionTypes.GET_RELATED_PRODUCTS_FAIL:
      return {
        ...state,
        loadingRelatedProducts: false,
        error: action.payload,
      };
	  case actionTypes.GET_RELATED_PRODUCT_DETAILS_RESET:
		  return {
        ...state,
        relatedProducts: [],
        loadingRelatedProducts: false,
      };
    //GET PRODUCT DETAIL
    case actionTypes.GET_PRODUCT_DETAILS_REQUEST:
      return {
        ...state,
        loadingProductDetails: true,
      };
    case actionTypes.GET_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        loadingProductDetails: false,
        product:  action.payload
      };
    case actionTypes.GET_PRODUCT_DETAILS_FAIL:
      return {
        ...state,
        loadingProductDetails: false,
        error: action.payload,
      };
    case actionTypes.GET_PRODUCT_DETAILS_RESET:
      return {
        ...state,
        product: {},
      };
      //GET VEHICLE TYPES
      case actionTypes.GET_VEHICLE_TYPES_REEQUEST:
      return {
        ...state,
        loadingVehicleTypes: true,
        errorVehicleTypes:''
      };
    
    case actionTypes.GET_VEHICLE_TYPES_SUCCESS:
      return {
        ...state,
        vehicleTypes: action.payload,
        loadingVehicleTypes: false,
      };
    case actionTypes.GET_VEHICLE_TYPES_FAIL:
      return {
        ...state,
        loadingVehicleTypes: false,
        error: action.payload,
      };
      //CREATE PRODUCT
    case actionTypes.CREATE_PRODUCT_REQUEST:
      
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        products: { fetchedProducts: [action.payload, ...state.products.fetchedProducts], count: state.products.count + 1 },
        productsCount: state.productsCount+1
      };
    case actionTypes.CREATE_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
   //UPDATE
    case actionTypes.UPDATE_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_PRODUCT_SUCCESS:
      const updatedList= state.products.fetchedProducts.map(product=>{
        if(product._id===action.payload._id){
          return action.payload
        }
        return product
      })
      return {
        ...state,
        loading: false,
        products: { fetchedProducts: updatedList, count: state.products.count }
      };
    case actionTypes.UPDATE_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
  
//DELETE PRODUCT
case actionTypes.DELETE_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_PRODUCT_SUCCESS:
      const updatedListAfterDelete= state.products.fetchedProducts.filter(product=> product._id!==action.payload._id)
      return {
        ...state,
        loading: false,
        products: { fetchedProducts: updatedListAfterDelete, count: state.products.count - 1},
        productsCount: state.productsCount-1
      };
    case actionTypes.DELETE_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      //GET OUT OF STOCK COUNT 
      case actionTypes.GET_OUT_OF_STOCK_COUNT_REQUEST:
      return {
        ...state,
        // error: '',
        loading: true,
        
      };
    case actionTypes.GET_OUT_OF_STOCK_COUNT_SUCCESS:
      return {
        ...state,
        outOfStockCount: action.payload,
        loading: false,
      };
    case actionTypes.GET_OUT_OF_STOCK_COUNT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      // GET TOTAL PRODUCTS COUNT
    case actionTypes.GET_TOTAL_PRODUCTS_REQUEST:
      return {
        ...state,
        // error:'',
        loadingProductsCount: true,
        
      };
    case actionTypes.GET_TOTAL_PRODUCTS_SUCCESS:
      return {
        ...state,
        productsCount: action.payload,
        loadingProductsCount: false,
      };
    case actionTypes.GET_TOTAL_PRODUCTS_FAIL:
      return {
        ...state,
        loadingProductsCount: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
//////////////////////////////////
// import * as actionTypes from "../constants/productConstants";

// export const getProductsReducer = (state = { products: [] }, action) => {
//   switch (action.type) {
//     case actionTypes.GET_PRODUCTS_REQUEST:
//       return {
//         loading: true,
//         products: [],
//       };
//     case actionTypes.GET_PRODUCTS_SUCCESS:
//       return {
//         products: action.payload,
//         loading: false,
//       };
//     case actionTypes.GET_PRODUCTS_FAIL:
//       return {
//         loading: false,
//         error: action.payload,
//       };
//     default:
//       return state;
//   }
// };

// export const getProductDetailsReducer = (state = { product: {} }, action) => {
//   switch (action.type) {
//     case actionTypes.GET_PRODUCT_DETAILS_REQUEST:
//       return {
//         loading: true,
//       };
//     case actionTypes.GET_PRODUCT_DETAILS_SUCCESS:
//       return {
//         loading: false,
//         product: action.payload,
//       };
//     case actionTypes.GET_PRODUCT_DETAILS_FAIL:
//       return {
//         loading: false,
//         error: action.payload,
//       };
//     case actionTypes.GET_PRODUCT_DETAILS_RESET:
//       return {
//         product: {},
//       };
//     default:
//       return state;
//   }
// };

// export const createProductReducer = (state = { products: [] }, action) => {
//   switch (action.type) {
//     case actionTypes.CREATE_PRODUCT_REQUEST:
//       return {
//         loading: true,
//       };
//     case actionTypes.CREATE_PRODUCT_SUCCESS:
//       return {
//         loading: false,
//         products: [...state.products, action.payload],
//       };
//     case actionTypes.UPDATE_PRODUCT_FAIL:
//       return {
//         loading: false,
//         error: action.payload,
//       };
//     default:
//       return state;
//   }
// };

// export const updateProductReducer = (state = { products: [] }, action) => {
//   switch (action.type) {
//     case actionTypes.UPDATE_PRODUCT_REQUEST:
//       return {
//         loading: true,
//       };
//     case actionTypes.UPDATE_PRODUCT_SUCCESS:
//       const updatedList= state.products.map(product=>{
//                 if(product._id===action.payload._id){
//                   return action.payload
//                 }
//                 return product
//         })
//         return {
//                 loading: false,
//                 products: updatedList,
//               };
//     case actionTypes.UPDATE_PRODUCT_FAIL:
//       return {
//         loading: false,
//         error: action.payload,
//       };
//     default:
//       return state;
//   }
// };

// export const deleteProductReducer = (state = { products: [] }, action) => {
//   switch (action.type) {
//     case actionTypes.DELETE_PRODUCT_REQUEST:
//       return {
//         loading: true,
//       };
//     case actionTypes.DELETE_PRODUCT_SUCCESS:
//       const updatedList= state.products.filter(product=>product._id!==action.payload._id)
//         return {
//           loading: false,
//           products: updatedList,
//       };
//     case actionTypes.DELETE_PRODUCT_FAIL:
//       return {
//         loading: false,
//         error: action.payload,
//       };
//     default:
//       return state;
//   }
// };