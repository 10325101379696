export const CREATE_BOOKING_REQUEST = "CREATE_BOOKING_REQUEST";
export const CREATE_BOOKING_SUCCESS = "CREATE_BOOKING_SUCCESS";
export const CREATE_BOOKING_FAIL = "CREATE_BOOKING_FAIL";

export const GET_BOOKINGS_REQUEST = "GET_BOOKINGS_REQUEST";
export const GET_BOOKINGS_SUCCESS = "GET_BOOKINGS_SUCCESS";
export const GET_BOOKINGS_FAIL = "GET_BOOKINGS_FAIL";

export const GET_TOTAL_BOOKINGS_REQUEST = "GET_TOTAL_BOOKINGS_REQUEST";
export const GET_TOTAL_BOOKINGS_SUCCESS = "GET_TOTAL_BOOKINGS_SUCCESS";
export const GET_TOTAL_BOOKINGS_FAIL = "GET_TOTAL_BOOKINGS_FAIL";

export const DELETE_BOOKING_REQUEST = "DELETE_BOOKING_REQUEST";
export const DELETE_BOOKING_SUCCESS = "DELETE_BOOKING_SUCCESS";
export const DELETE_BOOKING_FAIL = "DELETE_BOOKING_FAIL";