export const GET_ORDERS_REQUEST = "GET_ORDERS_REQUEST";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL";

export const GET_TOTAL_ORDERS_REQUEST = "GET_TOTAL_ORDERS_REQUEST";
export const GET_TOTAL_ORDERS_SUCCESS = "GET_TOTAL_ORDERS_SUCCESS";
export const GET_TOTAL_ORDERS_FAIL = "GET_TOTAL_ORDERS_FAIL";

export const GET_ORDER_DETAILS_REQUEST = "GET_ORDER_DETAILS_REQUEST";
export const GET_ORDER_DETAILS_SUCCESS = "GET_ORDER_DETAILS_SUCCESS";
export const GET_ORDER_DETAILS_FAIL = "GET_ORDER_DETAILS_FAIL";
export const GET_ORDER_DETAILS_RESET = "GET_ORDER_DETAILS_RESET";

export const CREATE_ORDER_REQUEST = "CREATE_ORDER_REQUEST";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_FAIL = "CREATE_ORDER_FAIL";

export const COMPLETE_SUCCESSFUL_ORDER_REQUEST = "COMPLETE_SUCCESSFUL_ORDER_REQUEST";
export const COMPLETE_SUCCESSFUL_ORDER_SUCCESS = "COMPLETE_SUCCESSFUL_ORDER_SUCCESS";
export const COMPLETE_SUCCESSFUL_ORDER_FAIL = "COMPLETE_SUCCESSFUL_ORDER_FAIL";

export const COMPLETE_FAILED_ORDER_REQUEST = "COMPLETE_FAILED_ORDER_REQUEST";
export const COMPLETE_FAILED_ORDER_SUCCESS = "COMPLETE_FAILED_ORDER_SUCCESS";
export const COMPLETE_FAILED_ORDER_FAIL = "COMPLETE_FAILED_ORDER_FAIL";

export const UPDATE_ORDER_REQUEST = "UPDATE_ORDER_REQUEST";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAIL = "UPDATE_ORDER_FAIL";

export const DELETE_ORDER_REQUEST = "DELETE_ORDER_REQUEST";
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS";
export const DELETE_ORDER_FAIL = "DELETE_ORDER_FAIL";

export const GET_ORDERS_BY_CUSTOMER_REQUEST = "GET_ORDERS_BY_CUSTOMER_REQUEST";
export const GET_ORDERS_BY_CUSTOMER_SUCCESS = "GET_ORDERS_BY_CUSTOMER_SUCCESS";
export const GET_ORDERS_BY_CUSTOMER_FAIL = "GET_ORDERS_BY_CUSTOMER_FAIL";

export const GET_ORDERS_BY_CUSTOMER_RESET = "GET_ORDERS_BY_CUSTOMER_RESET"

export const GET_CREATED_ORDER_RESET ="GET_CREATED_ORDER_RESET"