export const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL";

export const GET_SOME_PRODUCTS_REQUEST = "GET_SOME_PRODUCTS_REQUEST";
export const GET_SOME_PRODUCTS_SUCCESS = "GET_SOME_PRODUCTS_SUCCESS";
export const GET_SOME_PRODUCTS_FAIL = "GET_SOME_PRODUCTS_FAIL";

export const GET_TOTAL_PRODUCTS_REQUEST = "GET_TOTAL_PRODUCTS_REQUEST";
export const GET_TOTAL_PRODUCTS_SUCCESS = "GET_TOTAL_PRODUCTS_SUCCESS";
export const GET_TOTAL_PRODUCTS_FAIL = "GET_TOTAL_PRODUCTS_FAIL";
export const GET_RELATED_PRODUCT_DETAILS_RESET= "GET_RELATED_PRODUCT_DETAILS_RESET"

export const GET_OUT_OF_STOCK_COUNT_REQUEST = "GET_OUT_OF_STOCK_COUNT_REQUEST"
export const GET_OUT_OF_STOCK_COUNT_SUCCESS ="GET_OUT_OF_STOCK_COUNT_SUCCESS"
export const GET_OUT_OF_STOCK_COUNT_FAIL = "GET_OUT_OF_STOCK_COUNT_FAIL"

export const GET_RELATED_PRODUCTS_REQUEST = "GET_RELATED_PRODUCTS_REQUEST";
export const GET_RELATED_PRODUCTS_SUCCESS = "GET_RELATED_PRODUCTS_SUCCESS";
export const GET_RELATED_PRODUCTS_FAIL = "GET_RELATED_PRODUCTS_FAIL";

export const GET_PRODUCT_DETAILS_REQUEST = "GET_PRODUCT_DETAILS_REQUEST";
export const GET_PRODUCT_DETAILS_SUCCESS = "GET_PRODUCT_DETAILS_SUCCESS";
export const GET_PRODUCT_DETAILS_FAIL = "GET_PRODUCT_DETAILS_FAIL";
export const GET_PRODUCT_DETAILS_RESET = "GET_PRODUCT_DETAILS_RESET";

export const CREATE_PRODUCT_REQUEST = "CREATE_PRODUCT_REQUEST";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAIL = "CREATE_PRODUCT_FAIL";

export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL";

export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL";

export const GET_VEHICLE_TYPES_REEQUEST = "GET_VEHICLE_TYPES_REEQUEST";
export const GET_VEHICLE_TYPES_SUCCESS = "GET_VEHICLE_TYPES_SUCCESS";
export const GET_VEHICLE_TYPES_FAIL = "GET_VEHICLE_TYPES_FAIL";

export const GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST";
export const GET_CATEGORIES_SUCCESS= "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAIL= "GET_CATEGORIES_FAIL";



