export const GET_HEROS_REQUEST = "GET_HEROS_REQUEST";
export const GET_HEROS_SUCCESS = "GET_HEROS_SUCCESS";
export const GET_HEROS_FAIL = "GET_HEROS_FAIL";

export const CREATE_HERO_REQUEST = "CREATE_HERO_REQUEST";
export const CREATE_HERO_SUCCESS = "CREATE_HERO_SUCCESS";
export const CREATE_HERO_FAIL = "CREATE_HERO_FAIL";

export const UPDATE_HERO_REQUEST = "UPDATE_HERO_REQUEST";
export const UPDATE_HERO_SUCCESS = "UPDATE_HERO_SUCCESS";
export const UPDATE_HERO_FAIL = "UPDATE_HERO_FAIL";

export const DELETE_HERO_REQUEST = "DELETE_HERO_REQUEST";
export const DELETE_HERO_SUCCESS = "DELETE_HERO_SUCCESS";
export const DELETE_HERO_FAIL = "DELETE_HERO_FAIL";
