export const SET_CURRENT_USER= "SET_CURRENT_USER"

export const AUTH_USER_REQUEST= "AUTH_USER_REQUEST"
export const AUTH_USER_SUCCESS= "AUTH_USER_SUCCESS"
export const AUTH_USER_FAIL= "AUTH_USER_FAIL"

export const REGISTER_USER_REQUEST= "REGISTER_USER_REQUEST"
export const REGISTER_USER_SUCCESS= "REGISTER_USER_SUCCESS"
export const REGISTER_USER_FAIL= "REGISTER_USER_FAIL"


export const FORGOT_PASSWORD_REQUEST= "FORGOT_PASSWORD_REQUEST"
export const FORGOT_PASSWORD_SUCCESS= "FORGOT_PASSWORD_SUCCESS"
export const FORGOT_PASSWORD_FAIL= "FORGOT_PASSWORD_FAIL"