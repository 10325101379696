export const CREATE_VEHICLE_REQUEST= "CREATE_VEHICLE_REQUEST"
export const CREATE_VEHICLE_SUCCESS= "CREATE_VEHICLE_SUCCESS"
export const CREATE_VEHICLE_FAIL= "CREATE_VEHICLE_FAIL"

export const GET_VEHICLES_REQUEST= "GET_VEHICLES_REQUEST"
export const GET_VEHICLES_SUCCESS= "GET_VEHICLES_SUCCESS"
export const GET_VEHICLES_FAIL= "GET_VEHICLES_FAIL"

export const UPDATE_VEHICLE_REQUEST= "UPDATE_VEHICLE_REQUEST"
export const UPDATE_VEHICLE_SUCCESS= "UPDATE_VEHICLE_SUCCESS"
export const UPDATE_VEHICLE_FAIL= "UPDATE_VEHICLE_FAIL"

export const DELETE_VEHICLE_REQUEST= "DELETE_VEHICLE_REQUEST"
export const DELETE_VEHICLE_SUCCESS= "DELETE_VEHICLE_SUCCESS"
export const DELETE_VEHICLE_FAIL= "DELETE_VEHICLE_FAIL"
