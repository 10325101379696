import  * as actionTypes from '../constants/alertConstants';
import {v4 as uuidv4} from 'uuid';

 const setAlert = (message, alertType) => dispatch => {
    const id = uuidv4();
    dispatch({
        type: actionTypes.ADD_ALERT,
        payload: { message, alertType, id}
    })

    setTimeout(() => dispatch({
        type: actionTypes.REMOVE_ALERT,
         payload: id}), 4000)
}

export default setAlert;