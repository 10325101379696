import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import jwtDecode from 'jwt-decode'

// //Action
// import {setAuthorizationToken} from './actions/authActions'

// Reducers
import { cartReducer } from "./reducers/cartReducers";
// import {
//   getProductsReducer,
//   getProductDetailsReducer,
//   createProductReducer,
//   updateProductReducer,
//   deleteProductReducer
// } from "./reducers/productReducers";
import { currentUser } from "./reducers/authReducers"
import { product } from "./reducers/productReducers"
import { customer } from "./reducers/customerReducers"
import { order } from "./reducers/orderReducers"
import { request } from "./reducers/requestReducers"
import { vehicle } from "./reducers/vehicleReducers";
import { hero } from "./reducers/heroReducers";
import alert  from "./reducers/alertReducers";
import { service } from "./reducers/serviceReducers";
import { booking } from "./reducers/bookingReducers";



const reducer = combineReducers({
  cart: cartReducer,
  product,
  customer,
  order,
  request,
  currentUser,
  vehicle,
  alert,
  hero,
  service,
  booking
});

const middleware = [thunk];

const cartItemsInLocalStorage = localStorage.getItem("cart")
  ? JSON.parse(localStorage.getItem("cart"))
  : [];
const INITIAL_STATE = {
  cart: {
    cartItems: cartItemsInLocalStorage,
  },
};


const store = createStore(
  reducer,
  INITIAL_STATE,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;