import  { useLocation } from 'react-router-dom'

import  './Loading.css'

const Loading = () =>{
  const location= useLocation()
  const salwan= true
    return(salwan? 
            <div className='loading__screen'>

                  {location.pathname==='/checkout' &&  <h2  className='transaction__message'>Transaction is being processed</h2>}
                     <div className="spinner">
                        <div className="bubble-1"></div>
                        <div className="bubble-2"></div>
                     </div>

                     </div>
                    :
                    <div className='salwan'>
                     <div class="letter-holder">
                            <div className="l-1 letter">S</div>
                            <div className="l-2 letter">A</div>
                            <div className="l-3 letter">L</div>
                            <div className="l-4 letter">W</div>
                            <div className="l-5 letter">A</div>
                            <div className="l-6 letter">N</div>
                            <div className="l-8 letter">.</div>
                            <div className="l-9 letter">.</div>
                            <div className="l-10 letter">.</div>
                    </div>
                    </div>
    )
}

export default Loading

// import  './Loading.css'

// const Loading = () =>{
//   const salwan= true
//     return(
//             <div className='loading__screen'>
//                     <div class="letter-holder">
//                             <div className="l-1 letter">S</div>
//                             <div className="l-2 letter">A</div>
//                             <div className="l-3 letter">L</div>
//                             <div className="l-4 letter">W</div>
//                             <div className="l-5 letter">A</div>
//                             <div className="l-6 letter">N</div>
//                             <div className="l-8 letter">.</div>
//                             <div className="l-9 letter">.</div>
//                             <div className="l-10 letter">.</div>
//                     </div>
//                      <div className="spinner">
//                         <div className="bubble-1"></div>
//                         <div className="bubble-2"></div>
//                      </div>
                    
//                     </div>
//     )
// }

// export default Loading