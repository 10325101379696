import * as actionTypes from "../constants/vehicleConstants";
import  axiosInstance from '../../utils/api'
import setAlert from "./alertActions";
import { generateReqStatusObject, setState } from "../../utils/stateHelpers";

export const createVehicle = (newVehicle) => async (dispatch) => {
    try {
      dispatch({ type: actionTypes.CREATE_VEHICLE_REQUEST });
      const { data } = await axiosInstance.post("vehicles", newVehicle);
      dispatch({
        type: actionTypes.CREATE_VEHICLE_SUCCESS,
        payload: data,
      });
      dispatch(setAlert('Vehicle created successfully.', 'success'))
    } catch (error) {
      const errMessage = error.response && error.response.data.message ? error.response.data.message : error.message
      dispatch({
        type: actionTypes.CREATE_VEHICLE_FAIL,
        payload: errMessage
      });
    }
  };
  
  export const updateVehicle = (vehicle) => async (dispatch) => {
    try {
      dispatch({ type: actionTypes.UPDATE_VEHICLE_REQUEST });
      const { data } = await axiosInstance.put(`vehicles/${vehicle._id}`, vehicle);
      dispatch({
        type: actionTypes.UPDATE_VEHICLE_SUCCESS,
        payload: data,
      });
      dispatch(setAlert('Vehicle updated successfully.', 'success'))
    } catch (error) {
      const errMessage = error.response && error.response.data.message ? error.response.data.message : error.message
      dispatch({
        type: actionTypes.UPDATE_VEHICLE_FAIL,
        payload: errMessage
      });
    }
  };
  
  export const deleteVehicle = (vehicleId) => async (dispatch) => {
    try {
      
      dispatch({ type: actionTypes.DELETE_VEHICLE_REQUEST });
  
      const { data } = await axiosInstance.delete(`vehicles/${vehicleId}`);
      dispatch({
        type: actionTypes.DELETE_VEHICLE_SUCCESS,
        payload: data,
      });
      dispatch(setAlert('Vehicle deleted successfully.', 'success'))
    } catch (error) {
      const errMessage = error.response && error.response.data.message ? error.response.data.message : error.message 
      dispatch({
        type: actionTypes.DELETE_VEHICLE_FAIL,
        payload: errMessage
      });
    }
  };
  
  export const getVehicles = () => async (dispatch) => {
    try {
      dispatch({ type: actionTypes.GET_VEHICLES_REQUEST });
      
        const { data } = await axiosInstance.get("vehicles");
        dispatch({
          type: actionTypes.GET_VEHICLES_SUCCESS,
          payload: data,
        });
    } catch (error) {
      const errMessage = error.response && error.response.data.message ? error.response.data.message : error.message
      dispatch({
        type: actionTypes.GET_VEHICLES_FAIL,
        payload: errMessage
      });
    }
  };
  
  /*
  FETCH USING SWR
  */

  export const setVehicles = (data, error) => (dispatch) => {
    const reqStatus = generateReqStatusObject(actionTypes.GET_VEHICLES_REQUEST, actionTypes.GET_VEHICLES_SUCCESS, actionTypes.GET_VEHICLES_FAIL);
    setState(data, error, dispatch, reqStatus)
  };