import React from 'react'

import { useSelector } from 'react-redux'

const Alert = () => {

    const successStyle={
        backgroundColor: 'rgb(245, 255, 245)',//rgb(223, 242, 223)',
        color: 'rgb(92, 184, 92)',
        padding: '1rem'
    }
    const errorStyle={
        backgroundColor:'rgb(242, 216, 216)',// 'rgb(245, 207, 206)',
        color: 'rgb(212, 64, 59)',
        padding: '1rem'
    }
    
    const alerts= useSelector(state=>state.alert)
    
    return alerts !== null && alerts.length > 0 && alerts.map((alert) => {
        const currentStyle= alert.alertType==='success' ? successStyle:errorStyle
        return (<div key={alert.id} style={currentStyle}>
          {alert.message}
        </div>)
    });
}

    



export default Alert;