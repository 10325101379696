import * as actionTypes from "../constants/servicesConstants";
import loadingGif from "../../images/loading.gif";

export const service = (state = {
      services: [
        //   {
        //     name: "",
        //     price: "",
        //     image: loadingGif,
        //     includes: []
        //   }
        ],
          service: {
            name: "",
            price: "",
            image: loadingGif,
            includes: []
          },

     loadingServices: true,
     errorServices: '',
    loadingService: true,
    errorServcie: '' },
     action) => {


  switch (action.type) {
    //GET SERVICES
    case actionTypes.GET_SERVICES_REQUEST:
      return {
        ...state,
        loadingServices: true,
        errorServices:''
      };
    case actionTypes.GET_SERVICES_SUCCESS:
      return {
        ...state,
        services: action.payload,
        loadingServices: false,
      };
    case actionTypes.GET_SERVICES_FAIL:
      return {
        ...state,
        loadingServices: false,
        errorServices: action.payload,
      };
      //GET SERVICE
      case actionTypes.GET_SERVICE_REQUEST:
      return {
        ...state,
        loadingService: true,
        errorService:''
      };
    case actionTypes.GET_SERVICE_SUCCESS:
      return {
        ...state,
        service: action.payload,
        loadingService: false,
      };
    case actionTypes.GET_SERVICE_FAIL:
      return {
        ...state,
        loadingService: false,
        errorService: action.payload,
      };
      //CREATE HERO
    case actionTypes.CREATE_SERVICE_REQUEST:
      
      return {
        ...state,
        loadingServices: true,
      };
    case actionTypes.CREATE_SERVICE_SUCCESS:
      console.log(action.payload)
      return {
        ...state,
        loadingServices: false,
        services:  [action.payload, ...state.services]
      };
    case actionTypes.CREATE_SERVICE_FAIL:
      return {
        ...state,
        loadingServices: false,
        errorServices: action.payload,
      };
   //UPDATE
    case actionTypes.UPDATE_SERVICE_REQUEST:
      return {
        ...state,
        loadingServices: true,
        errorServices: ''
      };
    case actionTypes.UPDATE_SERVICE_SUCCESS:
      const updatedList= state.services.map(service=>{
        if(service._id===action.payload._id){
          return action.payload
        }
        return service
      })
      return {
        ...state,
        loadingServices: false,
        services:  updatedList
      };
    case actionTypes.UPDATE_SERVICE_FAIL:
      return {
        ...state,
        loadingServices: false,
        errorServices: action.payload,
      };
  
//DELETE
case actionTypes.DELETE_SERVICE_REQUEST:
      return {
        ...state,
        loadingServices: true,
        errorServices:''
      };
    case actionTypes.DELETE_SERVICE_SUCCESS:
      const updatedListAfterDelete= state.services.filter(service=> service._id!==action.payload._id)
      return {
        ...state,
        loadingServices: false,
        services:  updatedListAfterDelete
      };
    case actionTypes.DELETE_SERVICE_FAIL:
      return {
        ...state,
        loadingServices: false,
        errorServices: action.payload,
      };
    default:
      return state;
  }
};