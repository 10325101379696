export const GET_REQUESTS_REQUEST = "GET_REQUESTS_REQUEST";
export const GET_REQUESTS_SUCCESS = "GET_REQUESTS_SUCCESS";
export const GET_REQUESTS_FAIL = "GET_REQUESTS_FAIL";

export const GET_TOTAL_REQUESTS_REQUEST = "GET_TOTAL_REQUESTS_REQUEST";
export const GET_TOTAL_REQUESTS_SUCCESS = "GET_TOTAL_REQUESTS_SUCCESS";
export const GET_TOTAL_REQUESTS_FAIL = "GET_TOTAL_REQUESTS_FAIL";

export const CREATE_REQUEST_REQUEST = "CREATE_REQUEST_REQUEST";
export const CREATE_REQUEST_SUCCESS = "CREATE_REQUEST_SUCCESS";
export const CREATE_REQUEST_FAIL = "CREATE_REQUEST_FAIL";

export const DELETE_REQUEST_REQUEST = "DELETE_REQUEST_REQUEST";
export const DELETE_REQUEST_SUCCESS = "DELETE_REQUEST_SUCCESS";
export const DELETE_REQUEST_FAIL = "DELETE_REQUEST_FAIL";

export const DELETE_MANY_REQUESTS_REQUEST = "DELETE_MANY_REQUESTS_REQUEST";
export const DELETE_MANY_REQUESTS_SUCCESS = "DELETE_MANY_REQUESTS_SUCCESS";
export const DELETE_MANY_REQUESTS_FAIL = "DELETE_MANY_REQUESTS_FAIL";