import React from 'react'
import { Route, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoutes= ({component: Component, ...rest }) => {
  const history= useHistory();
    const cart = useSelector( state=> state.cart);
    const { cartItems } = cart
  return (
    <Route
      {...rest}
      render={(props) => {
        if (cartItems.length >0 || props.location.state) {
          return <Component {...props}/>;
        } else {
          return (
            history.push('/cart')
            // <Redirect
            //   to={'/cart'}
            // />
          );
        }
      }}
    />
  );
};

export default ProtectedRoutes