import "./App.css";
import { useState, useRef } from "react";
import { BrowserRouter as Router, useLocation} from "react-router-dom";
import jwtDecode from 'jwt-decode'
import {setAuthorizationToken, setCurrentUser} from './redux/actions/authActions'
import store from './redux/store'



// COMPONENTS IMPORT
import Alert from './components/Alert/Alert';
import Main from './components/Main/Main';
import Navbar from "./components/Navbar/Navbar";
import SideDrawer from "./components/SideDrawer/SideDrawer";
import Backdrop from "./components/Backdrop/Backdrop";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./components/SrollToTop/ScrollToTop";
// import ScrollToTop from "./components/SrollToTop/ScrollToTop";



if(localStorage.jwtToken){
	setAuthorizationToken(localStorage.jwtToken) 
	try{
    store.dispatch(setCurrentUser(jwtDecode(localStorage.jwtToken)));
	}catch(e){
		store.dispatch(setCurrentUser({}))
		
    }
}

function App() {
  const [sideToggle, setSideToggle]=useState(false)
  return (
    <Router>
     
       <div className='App'>
         <div>
       <ScrollToTop>
       <div className="content">
          <Navbar click={()=>setSideToggle(true)}/>
          <Alert/>
          <SideDrawer show={sideToggle} click={()=>setSideToggle(false)}/>
          <Backdrop show={sideToggle} click={()=>setSideToggle(false)}/>
            <Main/> 
        </div>
        
        </ScrollToTop>
        </div>
        <Footer/>
        </div>
        
    </Router>
  
  );
}

export default App;
