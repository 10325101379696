import * as actionTypes from "../constants/bookingConstants";
export const booking = (state = { 
    bookings: {fetchedBookings: [], count:0},
    bookingsCount: 0,
    loadingBookings: true, 
    errorBookings: '' },
    action) => {
  switch (action.type) {
    //GET BOOKINGS
    case actionTypes.GET_BOOKINGS_REQUEST:
      return {
        ...state,
        loadingBookings: true,
        errorBookings: ''
      };
    case actionTypes.GET_BOOKINGS_SUCCESS:
      return {
        ...state,
        bookings: {fetchedBookings: action.payload.bookings ,count: action.payload.count},
        loadingBookings: false,
        
      };
    case actionTypes.GET_BOOKINGS_FAIL:
      return {
        ...state,
        loadingBookings: false,
        errorBookings: action.payload,
      };
    
      //CREATE BOOKING
    case actionTypes.CREATE_BOOKING_REQUEST:
      
      return {
        ...state,
        loadingBookings: true,
        // error: ''
      };
    case actionTypes.CREATE_BOOKING_SUCCESS:
      return {
        ...state,
        loadingBookings: false,
        bookings: { fetchedBookings: [ action.payload, ...state.bookings.fetchedBookings ], count: state.bookings.count+1 },
        bookingsCount: state.bookingsCount+1

      };
    case actionTypes.CREATE_BOOKING_FAIL:
      return {
        ...state,
        loadingBookings: false,
        errorBookings: action.payload,
      };
  
//DELETE BOOKING
case actionTypes.DELETE_BOOKING_REQUEST:
      return {
        ...state,
        loadingBookings: true,
        // error:''
      };
    case actionTypes.DELETE_BOOKING_SUCCESS:
      const updatedListAfterDelete= state.bookings.fetchedBookings.filter(booking=> booking._id!==action.payload)
      return {
        ...state,
        loadingBookings: false,
        bookings: {fetchedBookings: updatedListAfterDelete, count: state.bookings.count - 1},
        bookingsCount: state.bookingsCount-1
      };
    case actionTypes.DELETE_BOOKING_FAIL:
      return {
        ...state,
        loadingBookings: false,
        errorBookings: action.payload,
      };
      // GET TOTAL BOOKINGS COUNT
    case actionTypes.GET_TOTAL_BOOKINGS_REQUEST:
      return {
        ...state,
        loadingBookings: true,
      };
    case actionTypes.GET_TOTAL_BOOKINGS_SUCCESS:
      
      return {
        ...state,
        bookingsCount: action.payload,
        loadingBookings: false,
      };
    case actionTypes.GET_TOTAL_BOOKINGS_FAIL:
      return {
        ...state,
        loadingBookings: false,
        errorBookings: action.payload,
      };
    default:
      return state;
  }
};