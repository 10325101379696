import { Link } from "react-router-dom"
import "./ShowcaseBrands.css"

//ASSETS
import teraflexIcon from "../../../../images/teraflex.png";
import bestopIcon from "../../../../images/Bestop.png";
import roughCountryIcon from "../../../../images/Rcountry.jpeg";
import foxIcon from "../../../../images/Fox.jpeg";

const brands=[
    {icon: teraflexIcon,
    link: `shop?brand=teraflex`},
    {icon: bestopIcon,
    link: `shop?brand=bestop`},
    {icon: roughCountryIcon,
    link: `shop?brand=rough_country`},
    {icon: foxIcon,
    link: `shop?brand=fox`},
]

const ShowcaseBrands =()=>{
    return <div className="showcase__brands">
        { brands.map((brand, index)=>{
                return <Link className="showcase__brandItem" to={brand.link} key={index}>
                               <img src={brand.icon} alt="vehicle by brand"/> 
                        </Link>  
        })
        }
    </div>

}

export default ShowcaseBrands