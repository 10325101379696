import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./ShowcaseVehicles.css"

//ACTION
import { getVehicles } from "../../../../redux/actions/vehicleActions";
//ASSETS
import imagePlaceholder from "../../../../images/placeholder.png";

const ShowcaseVehicles=()=>{
    const dispatch = useDispatch();
    const { vehicles, loadingVehicles, errorVehicles}= useSelector(state=> state.vehicle)

useEffect(()=>{
    dispatch(getVehicles())
},[dispatch])
    
    return loadingVehicles? <h2>Loading...</h2>: errorVehicles ? <h2>{errorVehicles}</h2>:<div className="showcase__vehicles">
        { vehicles.map((vehicle, index)=>{
            return <Link className="showcase__vehicles__item" to={`/shop?vehicle=${vehicle.name}`} key={index}>
                        <img src={vehicle.images[0].image || imagePlaceholder} alt={vehicle.name}/>
                        <div className="showcase__vehile__info">
                            <span className="showcase__vehile__name">{vehicle.name}</span>
                            <span className="showcase__vehile__year">({vehicle.yearRange})</span>
                        </div>
                    </Link>
        })

        }

    </div>
}

export default ShowcaseVehicles