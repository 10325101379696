import * as actionTypes from "../constants/authConstants";
import  axiosInstance,{setTokenHeader } from '../../utils/api'
import  setAlert  from './alertActions'



export const setCurrentUser=(user)=>{
   return  {
        type: actionTypes.AUTH_USER_SUCCESS,
        payload: user
    }
}

export const setAuthorizationToken=(token)=>{
	setTokenHeader(token);
}

export const logout=()=>{
  
	return dispatch => {
		localStorage.removeItem("jwtToken");
		setAuthorizationToken(false);
		dispatch(setCurrentUser({}));
    dispatch(setAlert('successfully logged out', 'success'))
	}
}


export const  authUser=(type,userData)=> async(dispatch)=>{
  try{

    dispatch({ type: actionTypes.AUTH_USER_REQUEST });
    const { data } = await axiosInstance.post(`auth/${type}`, userData);
    const {token, ...user}= data;
    localStorage.setItem("jwtToken", token);
    setAuthorizationToken(token);
    dispatch(setCurrentUser(user))

}catch(error){
      const err= error.response && error.response.data.message ? error.response.data.message : error.message
      console.log(err);
      // dispatch({
      //   type: actionTypes.AUTH_USER_FAIL,
      //   payload: err
      // });

      dispatch(setAlert(err, 'fail'))

  }
}
	

