import * as actionTypes from "../constants/heroConstants";
import axiosInstance from '../../utils/api'
import { setState, generateReqStatusObject } from "../../utils/stateHelpers";
import setAlert from "./alertActions";


export const createHero = (newHero) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.CREATE_HERO_REQUEST });
    const { data } = await axiosInstance.post("heros", newHero);

    dispatch({
      type: actionTypes.CREATE_HERO_SUCCESS,
      payload: data,
    });
    dispatch(setAlert('Hero created successfully.', 'success'))
  } catch (error) {
    
    const errMessage = error.response && error.response.data.message ? error.response.data.message : error.message
    dispatch({
      type: actionTypes.CREATE_HERO_FAIL,
      payload: errMessage
    });
  }
};

export const updateHero = (hero) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.UPDATE_HERO_REQUEST });

    const { data } = await axiosInstance.put(`heros/${hero._id}`, hero);
    
    dispatch({
      type: actionTypes.UPDATE_HERO_SUCCESS,
      payload: data,
    });
    dispatch(setAlert('Hero updated successfully.', 'success'))
  } catch (error) {
    const errMessage = error.response && error.response.data.message ? error.response.data.message : error.message
    dispatch({
      type: actionTypes.UPDATE_HERO_FAIL,
      payload: errMessage
    });
  }
};

export const deleteHero = (heroId) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.DELETE_HERO_REQUEST });
    const { data } = await axiosInstance.delete(`heros/${heroId}`);
    dispatch({
      type: actionTypes.DELETE_HERO_SUCCESS,
      payload: data,
    });
    dispatch(setAlert('Hero deleted successfully.', 'success'))
  } catch (error) {
    const errMessage = error.response && error.response.data.message ? error.response.data.message : error.message
    dispatch({
      type: actionTypes.DELETE_HERO_FAIL,
      payload: errMessage
    });
  }
};


export const getHeros = () => async (dispatch) => {
  
  try {
    dispatch({ type: actionTypes.GET_HEROS_REQUEST });
      
      const { data } = await axiosInstance.get("heros");
      dispatch({
        type: actionTypes.GET_HEROS_SUCCESS,
        payload:data,
      });
  } catch (error) {
   
    const errMessage = error.response && error.response.data.message ? error.response.data.message : error.message
    dispatch({
      type: actionTypes.GET_HEROS_FAIL,
      payload: errMessage
    });
  }
};


/*
FETCH USING SWR
*/


export const SetHeros = (data, error) => (dispatch) => {
  const reqStatus = generateReqStatusObject(actionTypes.GET_HEROS_REQUEST, actionTypes.GET_HEROS_SUCCESS, actionTypes.GET_HEROS_FAIL)
  setState(data, error, dispatch, reqStatus)
};
