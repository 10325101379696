import * as actionTypes from "../constants/productConstants";
import axios from "axios";
import axiosInstance from '../../utils/api'
import setAlert from "./alertActions";
import { generateReqStatusObject, setState } from "../../utils/stateHelpers";

const config = {
  headers : { 
    'Content-Type': 'application/json',
    'Accept': 'application/json'
   }}


export const createProduct = (newProduct) => async (dispatch) => {
  try {
console.log("Came to create product!")
    dispatch({ type: actionTypes.CREATE_PRODUCT_REQUEST });
    const { data } = await axiosInstance.post("products", newProduct);
    dispatch({
      type: actionTypes.CREATE_PRODUCT_SUCCESS,
      payload: data,
    });
    dispatch(setAlert('Product created successfully.', 'success'))
  } catch (error) {
    console.llg(error)
    const errMessage = error.response && error.response.data.message ? error.response.data.message : error.message
		// dispatch(setAlert(errMessage, 'error'))
    dispatch({
      type: actionTypes.CREATE_PRODUCT_FAIL,
      payload: errMessage
    });
  }
};

export const updateProduct = (product) => async (dispatch) => {
  try {
   
    dispatch({ type: actionTypes.UPDATE_PRODUCT_REQUEST });

    const { data } = await axiosInstance.put(`products/${product._id}`, product);
    
    dispatch({
      type: actionTypes.UPDATE_PRODUCT_SUCCESS,
      payload: data,
    });
    dispatch(setAlert('Product updated successfully.', 'success'))
  } catch (error) {
    const errMessage = error.response && error.response.data.message ? error.response.data.message : error.message
		// dispatch(setAlert(errMessage, 'error'))
    dispatch({
      type: actionTypes.UPDATE_PRODUCT_FAIL,
      payload: errMessage
    });
  }
};

export const deleteProduct = (productId) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.DELETE_PRODUCT_REQUEST });

    const { data } = await axiosInstance.delete(`products/${productId}`);
    dispatch({
      type: actionTypes.DELETE_PRODUCT_SUCCESS,
      payload: data,
    });
    dispatch(setAlert('Product deleted successfully.', 'success'))
  } catch (error) {
   
    const errMessage = error.response && error.response.data.message ? error.response.data.message : error.message
		// dispatch(setAlert(errMessage, 'error'))
    dispatch({
      type: actionTypes.DELETE_PRODUCT_FAIL,
      payload: errMessage
    });
  }
};


export const getProducts = (q, vehicle, category, subCategory,productsPerPage, pageNumber, sort,brand, outOfStock, qtyFilter) => async (dispatch) => {
  
  try {
    dispatch({ type: actionTypes.GET_PRODUCTS_REQUEST });
      let params = `?searchTerm=${q}&vehicle=${vehicle}&category=${category}&subCategory=${subCategory}&page=${pageNumber}&productsPerPage=${productsPerPage}&sort=${sort}&brand=${brand}&outOfStock=${outOfStock}&qtyFilter=${qtyFilter}`
      console.log(vehicle)
      const { data } = await axiosInstance.get("products"+ params);
      dispatch({
        type: actionTypes.GET_PRODUCTS_SUCCESS,
        payload: data,
      });
  } catch (error) {
   
    const errMessage = error.response && error.response.data.message ? error.response.data.message : error.message
		// dispatch(setAlert(errMessage, 'error'))
    dispatch({
      type: actionTypes.GET_PRODUCTS_FAIL,
      payload: errMessage
    });
  }
};

export const getRelatedProducts = (id/*, category, subCategory*/) => async (dispatch) => {
  
  
  try {
    dispatch({ type: actionTypes.GET_RELATED_PRODUCTS_REQUEST });
     
      const { data } = await axiosInstance.get("products/related-products/"+id);//, {category, subCategory});
	  
      dispatch({
        type: actionTypes.GET_RELATED_PRODUCTS_SUCCESS,
        payload: data,
      });
  } catch (error) {
    
    const errMessage = error.response && error.response.data.message ? error.response.data.message : error.message
		// dispatch(setAlert(errMessage, 'error'))
    dispatch({
      type: actionTypes.GET_RELATED_PRODUCTS_FAIL,
      payload: errMessage
    });
  }
};

export const getSomeProducts = (ids) => async (dispatch) =>{
  try {
    
    dispatch({ type: actionTypes.GET_SOME_PRODUCTS_REQUEST });
      const { data } = await axios.get("products/some-products", {...config, params: {ids}});
      dispatch({
        type: actionTypes.GET_SOME_PRODUCTS_SUCCESS,
        payload: data,
      });
  } catch (error) {
    
    const errMessage = error.response && error.response.data.message ? error.response.data.message : error.message
		// dispatch(setAlert(errMessage, 'error'))
    dispatch({
      type: actionTypes.GET_SOME_PRODUCTS_FAIL,
      payload: errMessage
    });
  }
}
export const getProductsCount = ( ) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_TOTAL_PRODUCTS_REQUEST });
    
    const { data } = await axiosInstance.get("products/totalProducts");
    dispatch({
      type: actionTypes.GET_TOTAL_PRODUCTS_SUCCESS,
      payload: data.totalproducts,
    });
  } catch (error) {
    
    const errMessage = error.response && error.response.data.message ? error.response.data.message : error.message
    dispatch({
      type: actionTypes.GET_TOTAL_PRODUCTS_FAIL,
      payload: errMessage
    });
  }
};

export const getCategories= () => async (dispatch) =>{
  try{
  dispatch({ type: actionTypes.GET_CATEGORIES_REQUEST });
  const { data } = await axiosInstance.get("products/categories")
  dispatch({
    type: actionTypes.GET_CATEGORIES_SUCCESS,
    payload: data,
  });
}catch(error){
  const errMessage = error.response && error.response.data.message ? error.response.data.message : error.message
    dispatch({
      type: actionTypes.GET_CATEGORIES_FAIL,
      payload: errMessage
    });
}
}



export const getVehicleTypes= () => async (dispatch) =>{
  try{
    // if(sessionStorage.getItem("vehicleTypes")){
    //     let data = JSON.parse(sessionStorage.getItem("vehicleTypes"))
    //     dispatch({
    //       type: actionTypes.GET_VEHICLE_TYPES_SUCCESS,
    //       payload: data,
    //     });
    // }
    // else{
  dispatch({ type: actionTypes.GET_VEHICLE_TYPES_REEQUEST });
  const { data } = await axiosInstance.get("vehicles/names");
     
  dispatch({
    type: actionTypes.GET_VEHICLE_TYPES_SUCCESS,
    payload: data,
  });
  // sessionStorage.setItem("vehicleTypes", JSON.stringify(data))
// }
}catch(error){
  const errMessage = error.response && error.response.data.message ? error.response.data.message : error.message
    dispatch({
      type: actionTypes.GET_VEHICLE_TYPES_FAIL,
      payload: errMessage
    });
}
}
export const getOutOfStockCount = ( ) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_OUT_OF_STOCK_COUNT_REQUEST });
    
    const { data } = await axiosInstance.get("products/out-of-stock-count");
    dispatch({
      type: actionTypes.GET_OUT_OF_STOCK_COUNT_SUCCESS,
      payload: data.outOfStockCount,
    });
  } catch (error) {
   
    const errMessage = error.response && error.response.data.message ? error.response.data.message : error.message
		// dispatch(setAlert(errMessage, 'error'))
    dispatch({
      type: actionTypes.GET_OUT_OF_STOCK_COUNT_FAIL,
      payload: errMessage
    });
  }
};
export const getProductDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_PRODUCT_DETAILS_REQUEST });

    const { data } = await axiosInstance.get(`products/${id}`);
      
    dispatch({
      type: actionTypes.GET_PRODUCT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
   
    const errMessage = error.response && error.response.data.message ? error.response.data.message : error.message
		// dispatch(setAlert(errMessage, 'error'))
    dispatch({
      type: actionTypes.GET_PRODUCT_DETAILS_FAIL,
      payload: errMessage
    });
  }
};

export const removeProductDetails = () => (dispatch) => {
       dispatch({ type: actionTypes.GET_PRODUCT_DETAILS_RESET });
};

export const removeRelatedProducts = () => (dispatch) => {
       dispatch({ type: actionTypes.GET_RELATED_PRODUCT_DETAILS_RESET });
};



// ALL FETCH USING SWR

export const setCategories = (data, error) => dispatch => {
  const reqStatus = generateReqStatusObject(actionTypes.GET_CATEGORIES_REQUEST, actionTypes.GET_CATEGORIES_SUCCESS, actionTypes.GET_CATEGORIES_FAIL)
  setState(data, error, dispatch, reqStatus);
}

export const setProducts = (data, error) => (dispatch) => {
  const reqStatus = generateReqStatusObject(actionTypes.GET_PRODUCTS_REQUEST, actionTypes.GET_PRODUCTS_SUCCESS, actionTypes.GET_PRODUCTS_FAIL)
  setState(data, error, dispatch, reqStatus)
};

export const setRelatedProducts = (data, error) => (dispatch) => {
  const reqStatus = generateReqStatusObject(actionTypes.GET_RELATED_PRODUCTS_REQUEST, actionTypes.GET_RELATED_PRODUCTS_SUCCESS, actionTypes.GET_RELATED_PRODUCTS_FAIL)
  setState(data, error, dispatch, reqStatus)
};

export const setSomeProducts = (data, error) => (dispatch) =>{
  const reqStatus = generateReqStatusObject(actionTypes.GET_SOME_PRODUCTS_REQUEST, actionTypes.GET_SOME_PRODUCTS_SUCCESS, actionTypes.GET_SOME_PRODUCTS_FAIL)
  setState(data, error, dispatch, reqStatus)
}

export const setProductsCount = ( data, error) => (dispatch) => {
    const reqStatus = generateReqStatusObject(actionTypes.GET_TOTAL_PRODUCTS_REQUEST, actionTypes.GET_TOTAL_PRODUCTS_SUCCESS, actionTypes.GET_TOTAL_PRODUCTS_FAIL);
    setState(data?.totalproducts, error, dispatch, reqStatus)
};

export const setVehicleTypes = (data, error) => (dispatch) => {
  const reqStatus = generateReqStatusObject(actionTypes.GET_VEHICLE_TYPES_REEQUEST, actionTypes.GET_VEHICLE_TYPES_SUCCESS, actionTypes.GET_VEHICLE_TYPES_FAIL);
  setState(data?.totalproducts, error, dispatch, reqStatus)
}