
import { useState,useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faChevronCircleLeft, faChevronCircleRight} from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components';
import "./ImageSlider.css";
//COMPONENTS
import SlideItem from '../SlideItem/SlideItem';
import SliderIndicator from "./SliderIndicator/SliderIndicator"
const Wrapper = styled.div `
 height: 70vh;
display: flex;
flex-wrap: nowrap;
overflow-x: hidden;
overflow-y:hidden;
position: relative;
`;

const Slide = styled.div`
  height: 100%;
  width: 100vw;
  flex-shrink: 0;
  background-position: center;
  background-size: cover;
  transition: 750ms all ease-in-out;
`;

const ImageSlider=({slides=[], autoPlay=true, autoPlayTime=7000})=>{

    const [currentSlide,setCurrentSlide]= useState(0)
    const [pauseSlider, setPauseSlider]= useState(false);
    
    const getNextSlide=()=>{
        if(currentSlide===slides.length-1){
            setCurrentSlide(0);
            return
        }
        setCurrentSlide(prevSlide=>prevSlide+1);
    }
    const getPreviousSlide=()=>{
        if(currentSlide===0){
            setCurrentSlide(slides.length-1);
            return
        }
        setCurrentSlide(prevSlide=>prevSlide-1);
    }
    useEffect(()=>{
       if(pauseSlider){
           return 
       }
        const timer =  setTimeout(()=>{
                getNextSlide();
        }, autoPlayTime)
       
       return ()=>{
            clearTimeout(timer)
        }
    },[currentSlide, pauseSlider])

    const setSlide=(index)=>{
            setCurrentSlide(index);
    }

    return <Wrapper 
     onMouseEnter={()=>setPauseSlider(true)}
    onMouseOut= {()=>setPauseSlider(false)}>
    {slides.map((slide, index)=>{
         return <Slide
          key={index}
          style={{
                backgroundImage:`url(${slide.image})`,
                marginLeft: index === 0 ? `-${currentSlide * 100}%` : undefined,
          }}
         >
               <SlideItem
                isCurrent={currentSlide===index}
                currentSlide={currentSlide}
                slides={slides}
                />
         </Slide> 
    })}
    { slides.length > 1 && 
    <>
        <SliderIndicator
        currentSlide={currentSlide}
        amountSlides={slides.length}
        setSlide={setSlide}
        />
      <FontAwesomeIcon icon={faChevronCircleLeft} id="slider__left__button" onClick={getPreviousSlide}/>
      <FontAwesomeIcon icon={faChevronCircleRight} id="slider__right__button" onClick={getNextSlide}/>
      </>
      }
</Wrapper>

}
export default ImageSlider