import React from 'react'
import { Route, useHistory } from "react-router-dom";
import {useSelector } from "react-redux";



const AlreadyLoggedIn= ({ Component, isSignup, ...rest }) => {
    const history= useHistory();
    const currentUser= useSelector(state=>state.currentUser);
    const { isAuthenticated} = currentUser;
    console.log(isSignup)
    
    
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated === false) {
          return <Component isSignup={isSignup} {...props}/>;
        } else {
          return (
            history.push('/')
          // <Redirect to={'/'}/>
          );
        }
      }}
    />
  );
};

export default AlreadyLoggedIn