import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useSWR from "swr";
import{ useHistory} from "react-router-dom";
import { /*getServices,*/ setServices } from "../../../../redux/actions/serviceActions";
import "./ServiceSection.css"
import Loading from "../../../../components/Loading/Loading";

const ServiceSection=({})=>{

   const dispatch= useDispatch()
    const { data, error } = useSWR(`${process.env.REACT_APP_BACKEND_BASE_URL}services`)

    const { services, loadingServices, errorServices } = useSelector(state=> state.service)

    useEffect(()=>{
        dispatch(setServices(data, error))
    },[dispatch, data, error])
  
    const history= useHistory();


    const goToService = (id) =>{
        history.push(`/services/${id}`)
    }



    return <div className="homescreen__service__section__background">  
    <div className="homescreen__service__section">
    <div className="homescreen__service__section__header">
          <span>Services we offer</span>
    </div>
    <div className="homescreen__service__section__header__content">
          <div className="homescreen__service__section__header__content__left">
             { loadingServices ? <Loading/> : errorServices ? <h2>{JSON.stringify(errorServices)}</h2> :
               services.map(service=>{
                  return <div key={service.name}className="home__service__item__group" onClick={()=>goToService(service._id)}>
                   <div className="home__Service__item">
                            {service.name}
                   </div>
                </div>
               })
                      
}
          </div>
          <div className="homescreen__service__section__header__content__right">
                      <button className="home__get__quote" onClick={()=>{
                          history.push('/contact')
                      }}>Get a qoute now</button>
          </div>
    </div>
    </div>
</div>
}

export default ServiceSection


// <div className="home__service__item__group">
// {/* {serviceIcon} */}
//  <div className="home__Service__item">
//           GENERAL MAINTENANCE
//  </div>
// </div>
// <div className="home__service__item__group">
// {/* {serviceIcon} */}
//  <div className="home__Service__item">
//           AIRCONDITIONING SERVICES
//  </div>
// </div>
// <div className="home__service__item__group">
// {/* {serviceIcon} */}
//  <div className="home__Service__item">
//        USED CAR PRE-PURCHASE INSPECTION
//  </div>
// </div>
// <div className="home__service__item__group">
// {/* {serviceIcon} */}
//  <div className="home__Service__item">
//       FABRICATION
//  </div>
// </div>
// <div className="home__service__item__group">
//  {/* {serviceIcon} */}
//  <div className="home__Service__item">
//           BODY REPAIR &#38; PAINT JOB
//  </div>
// </div>