import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import "./SlideItem.css";

const SlideItem=({ currentSlide, slides, isCurrent})=>{

const history= useHistory();
    

    const [body, setBody]= useState({
        header: slides[0].header,
        caption: slides[0].caption,
        action: slides[0].action
    })
    const { header, caption, action} = body
  
      useEffect(()=>{
          setBody({
            header: slides[currentSlide].header,
            caption: slides[currentSlide].caption,
            action: slides[currentSlide].action
          });
      },[currentSlide, history, slides])

const implementAction=(action)=>{
            if(action==="contact"){
              history.push("/contact")
              return
            }
            if(action==="shop"){
              history.push("/shop")
              return
            }  
}
    return <div className={isCurrent?'slideItem__content':"hide__Caption"}>
    <div className='slideItem__inner'>
          <div className={isCurrent? 'slideItem__inner__content' :"hide__Caption"}>
                {/* <span className={isCurrent? "hero__header": "hide__Caption"}>{header}</span> */}
                <span className={isCurrent?"hero__caption": "hide__Caption"}>{caption}</span>
          </div>
          <button className={isCurrent? 'call2action': "hide__Caption"} onClick={()=>implementAction(action)}>
                      { action==="contact" ?  `Contact us` : `Shop now`}
          </button>
    </div>
</div>
}

export default SlideItem