import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./HomeScreen.css";
//COMPONENTS IMPORT
import ShowcaseCategories from './HomeScreen/ShowcaseCategories/ShowcaseCategories';
import ShowcaseVehicles from './HomeScreen/ShowcaseVehicles/ShowcaseVehicles';
import ShowcaseBrands from './HomeScreen/ShowcaseBrands/ShowcaseBrands';
import ImageSlider from "./HomeScreen/ImageSlider/ImageSlider";
import ServiceSection from "./HomeScreen/ServiceSection/ServiceSection";
import HorizontalLineBreak from "../../components/HorizontalLineBreak";

//ASSETS
import image1 from "../../images/hero1.jpg";
import image2 from "../../images/hero4.jpg";
import image3 from "../../images/hero5.jpg";

//ACTIONS
import { getHeros } from "../../redux/actions/heroActions"

const slides=[
{image: image1, header: "All about Jeep", caption: "Get all Jeep related items here.", action:'contact'},
{image: image2, header: "Get the best services", caption: "Get all Jeep related items here.", action:'contact'},
{image: image3, header: "View our shop", caption: "Get all Jeep related items here.", action:'shop'}
]


function HomeScreen({props}) {
       const dispatch= useDispatch()
       const {heros, loadingHeros, errorHeros}= useSelector(state=>state.hero)

      useEffect(()=>{
            dispatch(getHeros())
      },[dispatch])

     
  return ( 
 <div className="homescreen">
      <ImageSlider 
            slides={heros}
            />

      <div className="homescreen__section">
            <p className="home__section__header">Shop by category</p>
            <HorizontalLineBreak text={"View all Products"}/>
            <ShowcaseCategories/>
      </div>
      <div className="homescreen__section">
            <p className="home__section__header">Shop by vehicle</p>
            <ShowcaseVehicles/>
      </div>
      <div className="homescreen__section">
            <p className="home__section__header">Some of our Brands</p>
            <HorizontalLineBreak text={"View all Products"}/>
            <ShowcaseBrands/>
      </div>
       <ServiceSection />
     </div>
  );
}

export default HomeScreen;