export const setState = (data, error, dispatch, reqStatus) =>{

  console.log(data, error)
    
    const { request, success, fail } = reqStatus;
        if(data === undefined && error === undefined){
            dispatch({ type: request });
        }

        if (error){
          const errMessage= error.response && error.response.data.message ? error.response.data.message : error.message
          dispatch({
            type: fail,
            payload: errMessage
          });
        }

        if(data){
          dispatch({
            type: success,
            payload: data,
          });
        }
}
export const generateReqStatusObject = (request, success, fail) =>{
        return { request, success, fail };
}

