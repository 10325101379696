import * as actionTypes from "../constants/heroConstants";
import image from "../../images/hero1.jpg";
import loadingGif from "../../images/loading.gif";

const defaultHeros = [{
  header: "All about Jeep",
  caption: "Get all Jeep related items here.",
  image: image,
  action: "shop"
}]

export const hero = (state = {
      heros: [{
            header: "",
            caption: "",
            image: loadingGif,
            action: ""
          }],
     loadingHeros: true,
     errorHeros: '' },
     action) => {


  switch (action.type) {
    //GET HEROS
    case actionTypes.GET_HEROS_REQUEST:
      return {
        ...state,
        loadingHeros: true,
        errorHeros:''
      };
    case actionTypes.GET_HEROS_SUCCESS:
      return {
        ...state,
        heros: action.payload.length ===0 ? defaultHeros : action.payload,
        loadingHeros: false,
      };
    case actionTypes.GET_HEROS_FAIL:
      return {
        ...state,
        loadingHeros: false,
        errorHeros: action.payload,
      };
      //CREATE HERO
    case actionTypes.CREATE_HERO_REQUEST:
      
      return {
        ...state,
        loadingHeros: true,
      };
    case actionTypes.CREATE_HERO_SUCCESS:
      console.log(action.payload)
      return {
        ...state,
        loadingHeros: false,
        heros:  [action.payload, ...state.heros]
      };
    case actionTypes.CREATE_HERO_FAIL:
      return {
        ...state,
        loadingHeros: false,
        errorHeros: action.payload,
      };
   //UPDATE
    case actionTypes.UPDATE_HERO_REQUEST:
      return {
        ...state,
        loadingHeros: true,
        errorHeros: ''
      };
    case actionTypes.UPDATE_HERO_SUCCESS:
      const updatedList= state.heros.map(hero=>{
        if(hero._id===action.payload._id){
          return action.payload
        }
        return hero
      })
      return {
        ...state,
        loadingHeros: false,
        heros:  updatedList
      };
    case actionTypes.UPDATE_HERO_FAIL:
      return {
        ...state,
        loadingHeros: false,
        errorHeros: action.payload,
      };
  
//DELETE HERO
case actionTypes.DELETE_HERO_REQUEST:
      return {
        ...state,
        loadingHeros: true,
        errorHeros:''
      };
    case actionTypes.DELETE_HERO_SUCCESS:
      const updatedListAfterDelete= state.heros.filter(hero=> hero._id!==action.payload._id)
      return {
        ...state,
        loadingHeros: false,
        heros:  updatedListAfterDelete
      };
    case actionTypes.DELETE_HERO_FAIL:
      return {
        ...state,
        loadingHeros: false,
        errorHeros: action.payload,
      };
    default:
      return state;
  }
};