import * as actionTypes from '../constants/alertConstants';

const initialState=[] 
//This is the error reducer function
export default (state=initialState, action)=>{
	
	    const {type,payload}= action;
	
	switch(type){
		case actionTypes.ADD_ALERT: 
			return [...state, payload]
		case actionTypes.REMOVE_ALERT:
			return state.filter(alert=> alert.id!==payload)
		default: return state;
	}
	
}