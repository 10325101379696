import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useSWR from "swr";
import "./ShowcaseCategories.css"

//ACTIONS
import {setCategories} from "../../../../redux/actions/productActions";

//ASSETS
import imagePlaceholder from "../../../../images/placeholder.png"
import exteriorImage from "../../../../images/exteriorCategory.jpg";
import engineImage from "../../../../images/engineCategory.jpg";
import equipmentImage from "../../../../images/equipmentCategory.jpg";
import interiorImage from "../../../../images/interiorCategory.jpg";
import lightingImage from "../../../../images/lightingCategory.jpeg";
import suspensionImage from "../../../../images/suspensionCategory.jpg";
import towingImage from "../../../../images/towingCategory.jpg";






const categories = [
    {
        category : 'Body_/_Exterior',
        image: exteriorImage
    },
    {
        category: 'Engine_/_Performance',
        image: engineImage
    },
    {
        category: 'Equipment',
        image: equipmentImage
    },
    {
        category: 'Interior',
        image: interiorImage
    },
    {
        category: 'Lighting',
        image: lightingImage
    },
    {
        category: 'Steering_/_Suspension_/_Wheels',
        image: suspensionImage
    },
    {
        category: 'Towing_/_Recovery',
        image: towingImage
    }
]


const ShowcaseCategories=()=>{

    // const dispatch= useDispatch();
    // const { data, error } = useSWR(`${process.env.REACT_APP_BACKEND_BASE_URL}products/categories`)
   
    // const productState = useSelector(state=> state.product)
    // const { categories, loading } = productState;

    console.log(categories)
    // useEffect(()=>{
    //     dispatch(setCategories(data, error))
    // },[ dispatch, data, error])

   
    
    return (
    <div className="showcase__categories">
        {
            categories.map((item, index)=>{
                return <Link className="showcase__category__item" to={`shop?category=${item.category}`} key={index}>
                            <div className="img__container"><img src={item.image} alt={item.category.split("_").join(" ")}/>
                            <div></div>
                            </div>
                            
                            <div>
                                <p className="showcase__category__name">{item.category.split("_").join(" ")}</p>
                                {/* <p className="showcase__category__count">({category.totalProducts} Products)</p> */}
                            </div>
                    </Link>
            })
        }

    </div>
    )
}
export default ShowcaseCategories