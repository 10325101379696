

import "./Main.css";
import { useEffect, lazy, Suspense } from "react"
import { Switch, Route, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { SWRConfig } from "swr";
import axios from "axios";
//ASSETS IMPORT
import whatsApp from '../../images/WhatsApp.png'
//SCREENS
import HomeScreen from "../../screens/HomeScreen/HomeScreen";
import Loading from "../Loading/Loading";

//PROTECTED ROUTES
import ProtectedRoutes from '../../hocs/ProtectedRoutes';
import ProtectedCheckout from '../../hocs/ProtectedCheckout';
import AlreadyLoggedIn from '../../hocs/AlreadyLoggedIn';
import { getVehicles } from "../../redux/actions/vehicleActions";

const LazyShopScreen = lazy(()=> import ("../../screens/ShopScreen/ShopScreen"))
const LazyProductScreen = lazy(()=> import ("../../screens/ProductScreen/ProductScreen"))
const LazyCartScreen = lazy(()=> import ("../../screens/CartScreen/CartScreen"))
const LazyAdminScreen = lazy(()=> import ("../../screens/AdminScreen/AdminScreen"))
const LazyCheckoutScreen = lazy(()=> import ("../../screens/CheckoutScreen/CheckoutScreen"))
const LazyAuthScreen = lazy(()=> import ("../../screens/AuthScreen/AuthScreen"))
const LazyServicesScreen = lazy(()=> import ("../../screens/ServicesScreen/ServicesScreen"))
const LazyAboutScreen = lazy(()=> import ("../../screens/AboutScreen/AboutScreen"))
const LazyContactScreen = lazy(()=> import ("../../screens/ContactScreen/ContactScreen"))
const LazyServiceScreen = lazy(()=> import ("../../screens/ServiceScreen/ServiceScreen"))

const LazyNotFound = lazy(()=> import ("../../components/NotFound/NotFound"))


const fetcher = async (...args) =>{
  let { data } = await axios.get(...args)
  return data

}


function Main() {
const dispatch= useDispatch();
  const { vehicles } = useSelector(state=>state.vehicle)
  const isAuthenticated = useSelector(state=>state.currentUser.isAuthenticated)
  const location= useLocation()
  useEffect(()=>{
    dispatch(getVehicles)
  },[dispatch])


  return ( 
  <SWRConfig value ={{fetcher}}>
  <main className='app'>
    <Suspense fallback={<Loading/>}>
        <Switch>
            <Route exact path="/" component={HomeScreen}/>
            <Route exact path ='/services' component={LazyServicesScreen}/>
            <Route exact path="/shop" render={(props) => ( <LazyShopScreen vehicles={vehicles}  {...props}/>)}/>
            <Route exact path="/about" component={LazyAboutScreen}/>
            <Route exact path="/contact" component={LazyContactScreen}/>
            <Route exact path="/product/:id" component={LazyProductScreen}/>
            <Route exact path="/services/:id" component={LazyServiceScreen}/>
            <Route exact path="/cart" component={LazyCartScreen}/>
            <ProtectedRoutes exact path="/admin" component={LazyAdminScreen} vehicles={vehicles}/>
            <ProtectedCheckout exact path="/checkout" component={LazyCheckoutScreen}/>
            <Route exact path="/register-admin" render={(props) =>(<LazyAuthScreen isSignup={true} {...props}/>)}/>
            <AlreadyLoggedIn exact path='/login-admin' component={LazyAuthScreen} isSignup={false}/>
            <Route component={LazyNotFound}/>
          </Switch>
          </Suspense>
        {location.pathname !=='/admin' &&
        <a href='https://wa.me/971566883866' className='whatsApp__icon__container'>
              <img src={whatsApp} alt='whatsapp salwan' className='whatsApp__icon'/>
          </a>}
          </main> 
    </SWRConfig>

  );
}

export default Main;