import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faBars, faShoppingCart} from '@fortawesome/free-solid-svg-icons'

import './Navbar.css';
import { NavLink, Link, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Logo from '../../images/brandIcon.png'
import { logout } from '../../redux/actions/authActions';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';

function Navbar({click}) {
   
  const style={
    borderBottom:'4px solid #ad0207',
     transition: '0.8'
   }
   const cartStyle={
     color: '#ad0207'
   }
   const location= useLocation()
    const dispatch = useDispatch()  
    const history= useHistory()
    const cart = useSelector((state) => state.cart);
    const currentUser= useSelector( state=> state.currentUser)
 
    
  const { cartItems } = cart;
  const { isAuthenticated} = currentUser;

  const getCartCount = () => {
    return cartItems.reduce((qty, item) => Number(item.qty) + qty, 0);
  };
  const handleLogout=()=>{

		dispatch(logout())
		history.push("/")
	}

  return (
    <nav className='salwan__navbar'>
      <div className='salwan__navbar__container'>
        <div className="salwan__navbar__logo">
          <Link to={'/'} className='brand__icon'>
           <img src={Logo} alt="salwan autos"/>
          </Link>
        </div>
      <ul className="salwan__navbar__links">
      <li>
          <NavLink exact to="/" activeStyle={style}>HOME</NavLink>
      </li>
      <li>
        <NavLink to="/shop" activeStyle={style}>SHOP</NavLink>
      </li>
        {isAuthenticated ?
        <>
        <li>
          <NavLink to='/admin' activeStyle={style}>ADMIN</NavLink>
        </li>
        <li>
           <NavLink to="/" onClick={handleLogout}>LOGOUT</NavLink>
        </li>
        </>
        :
        <>
        <li>
          <NavLink to="/services" activeStyle={style}>SERVICES</NavLink>
        </li>
        <li>
          <NavLink to="/about" activeStyle={style}>ABOUT</NavLink>
        </li>
        <li>
          <NavLink to="/contact" activeStyle={style}>CONTACT</NavLink>
        </li>
        </>
        }
        {/* <li>
          <NavLink to="/cart" id='cart__link'>
              <span id={location.pathname==='/cart'?'currently__cart': ''}>
              <FontAwesomeIcon icon={faShoppingCart}/>{getCartCount()}
              </span>
          </NavLink>
        </li> */}
      </ul>

      <div className='nav__cart__container'>
          <NavLink to="/cart" id='cart__link'>
              <span id={location.pathname==='/cart'?'currently__cart': ''}>
              <FontAwesomeIcon icon={faShoppingCart}/>{getCartCount()}
              </span>
          </NavLink>
      </div>
        <HamburgerMenu click={click} navBar={true}/>
      </div>
    </nav>
  );
}

export default Navbar;