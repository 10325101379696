export const GET_CUSTOMERS_REQUEST = "GET_CUSTOMERS_REQUEST";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS_FAIL = "GET_CUSTOMERS_FAIL";

export const GET_TOTAL_CUSTOMERS_REQUEST = "GET_TOTAL_CUSTOMERS_REQUEST";
export const GET_TOTAL_CUSTOMERS_SUCCESS = "GET_TOTAL_CUSTOMERS_SUCCESS";
export const GET_TOTAL_CUSTOMERS_FAIL = "GET_TOTAL_CUSTOMERS_FAIL";

export const GET_CUSTOMER_DETAILS_REQUEST = "GET_CUSTOMER_DETAILS_REQUEST";
export const GET_CUSTOMER_DETAILS_SUCCESS = "GET_CUSTOMER_DETAILS_SUCCESS";
export const GET_CUSTOMER_DETAILS_FAIL = "GET_CUSTOMER_DETAILS_FAIL";
export const GET_CUSTOMER_DETAILS_RESET = "GET_CUSTOMER_DETAILS_RESET";

export const CREATE_CUSTOMER_REQUEST = "CREATE_CUSTOMER_REQUEST";
export const CREATE_CUSTOMER_SUCCESS = "CREATE_CUSTOMER_SUCCESS";
export const CREATE_CUSTOMER_FAIL = "CREATE_CUSTOMER_FAIL";

export const UPDATE_CUSTOMER_REQUEST = "UPDATE_CUSTOMER_REQUEST";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_FAIL = "UPDATE_CUSTOMER_FAIL";

export const DELETE_CUSTOMER_REQUEST = "DELETE_CUSTOMER_REQUEST";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_FAIL = "DELETE_CUSTOMER_FAIL";