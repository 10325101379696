import axios from 'axios'

export function setTokenHeader(token){
  
	if(token){//when a user logs in, we ppass the token to all future requests
		axios.defaults.headers.common['Authorization']= `Bearer ${token}`
	} else{ //when a user logsout we delete the token
		delete axios.defaults.headers.common['Authorization'];
		
	}
}

const base_url=
  process.env.REACT_APP_NODE_ENV === "development"
    ? "http://localhost:5000/api/" :
    process.env.REACT_APP_BACKEND_BASE_URL
    // "https://glacial-eyrie-47095.herokuapp.com/"
   

const axiosInstance= axios.create({
  baseURL: base_url,
  headers: { 
   'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
})



axiosInstance.interceptors.request.use(
  (request) => {
    let token = localStorage.getItem("jwtToken");
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
  }
 
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosInstance;

